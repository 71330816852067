// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import '../App.css';
import { FaFacebook, FaMedium, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer">
            <div className="footer-left">
            <span>© {new Date().getFullYear()} Ideal Scribe. All rights reserved.</span>
            </div>
            <nav className="footer-links">
            <Link to="/terms-of-service">Terms of Service</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/support">Support</Link>
            <div className="social-links">
                <a href="https://medium.com" target="_blank" rel="noopener noreferrer"><FaMedium /></a>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            </div>
            </nav>
        </div>
    </footer>
    
  );
};

export default Footer;
