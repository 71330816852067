import React, { useState, useEffect, useRef } from 'react';
import './AccountSettings.css'; // Ensure the CSS matches the updated design
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebook, FaMedium, FaTwitter } from 'react-icons/fa';
import axios from 'axios'; // Import axios for making API requests
import { fetchAuthSession, signOut, updatePassword } from '@aws-amplify/auth';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 
  'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 
  'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 
  'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 
  'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming', 'N/A'
];

const countries = [
  'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia',
  'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin',
  'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi',
  'Cabo Verde', 'Cambodia', 'Cameroon', 'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia',
  'Comoros', 'Congo (Congo-Brazzaville)', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark',
  'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea',
  'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana',
  'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hungary', 'Iceland',
  'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya',
  'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein',
  'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands',
  'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique',
  'Myanmar (Burma)', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea',
  'North Macedonia', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine State', 'Panama', 'Papua New Guinea', 'Paraguay',
  'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia',
  'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia',
  'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea',
  'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand',
  'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine',
  'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela',
  'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
];

const timezones = [
  { label: '(UTC-12:00) International Date Line West', value: 'Etc/GMT+12' },
  { label: '(UTC-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
  { label: '(UTC-10:00) Hawaii', value: 'Pacific/Honolulu' },
  { label: '(UTC-09:00) Alaska', value: 'America/Anchorage' },
  { label: '(UTC-08:00) Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
  { label: '(UTC-07:00) Mountain Time (US & Canada)', value: 'America/Denver' },
  { label: '(UTC-06:00) Central Time (US & Canada)', value: 'America/Chicago' },
  { label: '(UTC-05:00) Eastern Time (US & Canada)', value: 'America/New_York' },
  { label: '(UTC-04:00) Caracas, La Paz', value: 'America/Caracas' },
  { label: '(UTC-03:00) Brasilia', value: 'America/Sao_Paulo' },
  { label: '(UTC-02:00) Mid-Atlantic', value: 'Atlantic/South_Georgia' },
  { label: '(UTC-01:00) Azores', value: 'Atlantic/Azores' },
  { label: '(UTC+00:00) London, Lisbon, Casablanca', value: 'Europe/London' },
  { label: '(UTC+01:00) Berlin, Madrid, Paris', value: 'Europe/Berlin' },
  { label: '(UTC+02:00) Cairo, Jerusalem', value: 'Africa/Cairo' },
  { label: '(UTC+03:00) Moscow, Riyadh, Baghdad', value: 'Asia/Riyadh' },
  { label: '(UTC+03:30) Tehran', value: 'Asia/Tehran' },
  { label: '(UTC+04:00) Abu Dhabi, Muscat', value: 'Asia/Dubai' },
  { label: '(UTC+04:30) Kabul', value: 'Asia/Kabul' },
  { label: '(UTC+05:00) Islamabad, Karachi', value: 'Asia/Karachi' },
  { label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'Asia/Kolkata' },
  { label: '(UTC+06:00) Dhaka', value: 'Asia/Dhaka' },
  { label: '(UTC+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
  { label: '(UTC+08:00) Beijing, Hong Kong, Singapore', value: 'Asia/Hong_Kong' },
  { label: '(UTC+09:00) Tokyo, Seoul, Osaka', value: 'Asia/Tokyo' },
  { label: '(UTC+09:30) Adelaide, Darwin', value: 'Australia/Adelaide' },
  { label: '(UTC+10:00) Sydney, Guam', value: 'Australia/Sydney' },
  { label: '(UTC+11:00) Solomon Islands', value: 'Pacific/Guadalcanal' },
  { label: '(UTC+12:00) Auckland, Fiji', value: 'Pacific/Auckland' },
];

const AccountSettings = () => {
  const [profile, setProfile] = useState({
    userName: '',
    firstName: '',
    lastName: '',
    street: '', 
    city: '',
    state: '',
    zipCode: '',
    country: 'United States',
    email: '',
    phone: '',
    newsLetter: true,
    additionalEmails: [],
    newEmail: '',
    timezone: '',
  });

  const [subscription, setSubscription] = useState({
    plan: 'Basic',
    planExpiration: 'N/A',
  });
  const [sessionProcessed, setSessionProcessed] = useState(false);  // Track if the session was processed
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [resetError, setResetError] = useState('');
  const [isNightMode, setIsNightMode] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [selectedSection, setSelectedSection] = useState('Profile');
  const [showSubscriptionOptions, setShowSubscriptionOptions] = useState(false); // State for showing subscription options
  const [appearance, setAppearance] = useState({
    theme: 'Light',
    categorization: {
      Blue: 'Travel',
      Green: 'To do',
      Red: 'Shopping',
      Purple: 'Health',
      Yellow: 'Food',
      Orange: 'Entertainment',
      LightBlue: 'Reminder',
    },
  });
  const [loading, setLoading] = useState(false); // State to track loading status
  const location = useLocation(); 

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const settingsParam = queryParams.get('settings');
  
    // Set Account section if "settings=true" is present
    if (settingsParam === 'true') {
      setSelectedSection('Account');
    }
  
    // Check if session ID is present and hasn't been processed yet
    // Only call updateSubscriptionStatus if session_id exists and hasn't been processed
    if (sessionId && !sessionStorage.getItem(sessionId)) {
      updateSubscriptionStatus(sessionId);
    }
}, [location]);
  

  const getUserFromToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.sub;
    } catch (error) {
      console.error('Error parsing JWT token:', error);
      return null;
    }
  };

  const getEmailFromToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.email;
    } catch (error) {
      console.error('Error parsing JWT token:', error);
      return null;
    }
  };

  const updateSubscriptionStatus = async (sessionId) => {
    setLoading(true); // Start loading when checkout is initiated
    try {

      if (sessionStorage.getItem(sessionId)) {
        return;  // Exit if this session has already been processed
      }

      

      const session = await fetchAuthSession();
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();
  
      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token}`,
      };
  
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/subscription`;
  
      const response = await axios.post(url, { sessionId }, { headers });
      if (response.data && response.data.newPlan) {
        setSubscription({
          plan: response.data.newPlan,
          planExpiration: response.data.planExpiration,
        });

      alert(`Subscription updated to ${response.data.newPlan}`);
      fetchUserData(); // Refresh user data to reflect changes

      // Mark this session ID as processed in sessionStorage
      sessionStorage.setItem(sessionId, "processed");

        fetchUserData(); // Refresh user data to reflect changes
      } else {
        alert('Failed to update subscription. Please contact support.');
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      alert('Failed to update subscription. Please try again later.');
    } finally {
      setLoading(false); // Stop loading once the process completes
    }
  };
  

  const fetchUserData = async () => {
    try {
      // Get the user's session to retrieve the JWT token
      const session = await fetchAuthSession(); // Modify as needed based on your authentication setup
      const jwtToken = session?.idToken?.jwtToken;
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();

      // Extract the email from the JWT token
      const user = getUserFromToken(token);

      if (!user) {
        alert('Failed to retrieve user from JWT token.');
        return;
      }

      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token}`
      };

      // Define the API URL with the userID path parameter (adjust as necessary)
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/users/${encodeURIComponent(user)}`;

      // Make the GET request to the API with JWT in the header
      const response = await axios.get(url, { headers });

      // Assuming the response is an array with one object
      const userData = response.data[0];

      // Set the state based on the retrieved data
      //Street, userEmailsAccessList, newsLetter, planExpiration
      setProfile({
        userName: userData.userName || '',
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        street: userData.street || '',
        city: userData.city || '', // Assuming city is missing from your API response; adjust as necessary
        state: userData.state || '', // Assuming state is missing from your API response; adjust as necessary
        zipCode: userData.zipCode || '',
        country: userData.country || 'United States',
        email: userData.userEmails || '',
        phone: userData.phone || '',
        newsLetter: userData.newsLetter !== undefined ? userData.newsLetter : false, 
        additionalEmails: userData.userEmailsAccessList
        ? userData.userEmailsAccessList.split('|')
        : [],
        newEmail: '',
        timezone: userData.timezone || '', // Assuming timezone is missing from your API response; adjust as necessary
      });

      setSubscription({
        plan: userData.plan || 'Basic',
        planExpiration: userData.planExpiration || 'N/A',
      });

      setAppearance({
        theme: userData.theme || 'Light',
        categorization: userData.categorization || { // Directly mapping categorization object
          Red: 'Shopping',
          Orange: 'Entertainment',
          Green: 'Nature',
          Blue: 'Travel',
          Yellow: 'Food',
          Purple: 'Health',
          LightBlue: 'Reminder',
        },
      });

      setIsNightMode(userData.theme === 'Dark');
    } catch (error) {
      console.error('Error fetching user data:', error);
      alert('Failed to load user data. Please try again later.');
    }
  };

  useEffect(() => {
    // Fetch user data on component load
    fetchUserData();
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    if (isNightMode) {
      root.classList.add('dark-mode'); // Add dark mode class
      root.style.setProperty('--bg-color', '#333');
      root.style.setProperty('--text-color', '#fff');
      root.style.setProperty('--sidebar-bg-color', '#444');
      root.style.setProperty('--note-bg-color', '#555');
      root.style.setProperty('--button-bg-color', '#555');
      root.style.setProperty('--button-hover-bg-color', '#666');
    } else {
      root.style.setProperty('--bg-color', '#fff');
      root.style.setProperty('--text-color', '#000');
      root.style.setProperty('--sidebar-bg-color', '#f8f9fa');
      root.style.setProperty('--note-bg-color', '#fff');
      root.style.setProperty('--button-bg-color', '#007bff');
      root.style.setProperty('--button-hover-bg-color', '#0056b3');
    }
  }, [isNightMode]);
  const [showResetPrompt, setShowResetPrompt] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const navigate = useNavigate();

  const createPatchPayload = () => {
    const payload = {};
  
    // Include all fields regardless of their value
    payload.userName = profile.userName || '';
    payload.firstName = profile.firstName || '';
    payload.lastName = profile.lastName || '';
    payload.street = profile.street || '';
    payload.city = profile.city || '';
    payload.state = profile.state || '';
    payload.zipCode = profile.zipCode || '';
    payload.country = profile.country || 'United States';
    payload.userEmails = profile.email || '';
    payload.phone = profile.phone || '';
    payload.newsLetter = profile.newsLetter; // Always include the checkbox value
    payload.timezone = profile.timezone || '';
  
    if (profile.additionalEmails.length) {
      payload.userEmailsAccessList = profile.additionalEmails.join('|');
    }
  
    payload.plan = subscription.plan || 'Basic';
    payload.planExpiration = subscription.planExpiration || 'N/A';
    payload.theme = appearance.theme || 'Light';
    payload.categorization = appearance.categorization || {};
  
    return payload;
  };
  

  const handleProfileChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setResetError('New passwords do not match.');
      return;
    }

    try {
      await updatePassword({ oldPassword, newPassword });
      alert('Password updated successfully!');
      setShowPasswordReset(false);
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setResetError('');
    } catch (err) {
      console.log(err);
      setResetError('Failed to update password. Please try again.');
    }
  };

  const handlePasswordReset = () => {
    setShowPasswordReset(true);
    setResetError('');
  };

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  const handleAddEmail = () => {
    if (profile.newEmail && !profile.additionalEmails.includes(profile.newEmail)) {
      setProfile((prev) => ({
        ...prev,
        additionalEmails: [...prev.additionalEmails, prev.newEmail],
        newEmail: '',
      }));
    }
  };

  const handleRemoveEmail = (email) => {
    setProfile((prev) => ({
      ...prev,
      additionalEmails: prev.additionalEmails.filter((e) => e !== email),
    }));
  };

  const handleAppearanceChange = (e) => {
    const { name, value } = e.target;
    setAppearance((prev) => ({
      ...prev,
      categorization: { ...prev.categorization, [name]: value },
    }));
  };


  const handleSendResetLink = () => {
    if (resetEmail) {
      alert(`Password reset link sent to ${resetEmail}`);
      setShowResetPrompt(false);
    } else {
      alert('Please enter your email address.');
    }
  };

  const handleSubscriptionUpgrade = () => {
    ReactGA.event({
      category: 'User',
      action: 'Manage Subscription Clicked',
    });

    navigate('/plan-subscription');
  };

  const handleSave = async () => {
    try {
      const session = await fetchAuthSession();
      const jwtToken = session?.idToken?.jwtToken;
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();

      const user = getUserFromToken(token);

      if (!user) {
        alert('Failed to retrieve user from JWT token.');
        return;
      }

      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token}`,
      };
      const apiUrl = process.env.REACT_APP_API_URL; 
      const url = `${apiUrl}/users/${encodeURIComponent(user)}`;
      const patchData = createPatchPayload();

      await axios.patch(url, patchData, { headers });
      alert('Settings have been saved successfully.');
    } catch (error) {
      console.error('Error updating user data:', error);
      alert('Failed to update settings. Please try again later.');
    }
  };

  const SidebarMenu = () => (
    <div className="sidebar-menu">
      <ul>
        <li
          className={selectedSection === 'Profile' ? 'active' : ''}
          onClick={() => setSelectedSection('Profile')}
        >
          Profile
        </li>
        <li
          className={selectedSection === 'Account' ? 'active' : ''}
          onClick={() => setSelectedSection('Account')}
        >
          Account Settings
        </li>
      </ul>
    </div>
  );

  return (
    <div className="account-settings-container">
      <div className="account-settings-banner">
        <div className="account-settings-banner-left">
          <img src="/logo.png" alt="Ideal Scribe" className="account-settings-logo" />
          <a href="/" className="account-settings-title-link">
            <h1 className="account-settings-title">Ideal Scribe</h1>
          </a>
          <span className="account-settings-beta-label">beta</span>
        </div>
        <nav className="account-settings-nav-links">
        
          <Link to="/app">Quick Notes</Link>
          <Link to="/startup-guide">Startup Guide</Link>
          <button className="night-mode-toggle" onClick={handleLogout}>Logout</button>
        </nav>
      </div>

      <div className="account-settings-main-content">
        <SidebarMenu />
        <div className="account-settings-content">
          {selectedSection === 'Profile' ? (
            <div className="account-settings-box">
              <h1>Profile Information</h1>
              <form className="account-settings-form">
                <label>Username</label>
                <input
                  type="text"
                  name="userName"
                  value={profile.userName}
                  onChange={handleProfileChange}
                />
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={profile.firstName}
                  onChange={handleProfileChange}
                />
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={profile.lastName}
                  onChange={handleProfileChange}
                />
                <label>Street Address</label>
                <input
                  type="text"
                  name="street"
                  value={profile.street}
                  onChange={handleProfileChange}
                />
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={profile.city}
                  onChange={handleProfileChange}
                />
                <label>State</label>
                <select
                  className="filter-select-modern"
                  name="state"
                  value={profile.state}
                  onChange={handleProfileChange}
                >
                  <option value="">Select a state</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <label>Zip Code</label>
                <input
                  type="text"
                  name="zipCode"
                  value={profile.zipCode}
                  onChange={handleProfileChange}
                />
                <label>Country</label>
                <select
                  className="filter-select-modern"
                  name="country"
                  value={profile.country}
                  onChange={handleProfileChange}
                >
                  <option value="">Select a country</option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                <label>Email</label>
                <div style={{ marginBottom: '10px' }}>
                  <span>{profile.email}</span>
                </div>
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={profile.phone}
                  onChange={handleProfileChange}
                />
                <label>Time Zone</label>
                <select
                  name="timezone"
                  value={profile.timezone}
                  onChange={handleProfileChange}
                  className="filter-select-modern"
                >
                  <option value="">Select a time zone</option>
                  {timezones.map((zone) => (
                    <option key={zone.value} value={zone.value}>
                      {zone.label}
                    </option>
                  ))}
                </select>

                <table className="updates-checkbox-table">
                  <tbody>
                  <tr>
                    <td><label>Sign up for news and updates: </label></td>
                    <td>
                      <input
                        type="checkbox"
                        name="newsLetter"
                        checked={profile.newsLetter}
                        onChange={handleProfileChange}
                        className="checkbox-right"
                      />
                    </td>
                  </tr>
                  </tbody>
                </table>

                <div className="password-section">
                  <label>Password</label>
                  <button type="button" className="modern-button" onClick={handlePasswordReset}>
                    Reset Password
                  </button>
                  {showPasswordReset && (
                    <div className="password-reset-form">
                      <h4>Reset Password</h4>
                      <input
                        type="password"
                        placeholder="Old Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      /><br/><br/>
                      <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      /><br/>
                      <input
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      /><br/>
                      {resetError && <p className="error-message">{resetError}</p>}
                      <button type="button"  className="modern-button" onClick={handleUpdatePassword}>Update Password</button>
                    </div>
                  )}  
                </div>


                <div className="manage-emails">
                  <label>
                    Manage Email Addresses
                    <span className="tooltip-container">
                      <span className="tooltip-icon"></span>
                      <span className="tooltip-text">
                        You can add additional email addresses to ingest new notes via multiple emails.
                      </span>
                    </span>
                  </label>
                  <input
                    type="email"
                    placeholder="Add new email"
                    value={profile.newEmail}
                    onChange={(e) => setProfile({ ...profile, newEmail: e.target.value })}
                  />
                  <button type="button" onClick={handleAddEmail}>
                    Add Email
                  </button>
                  <ul>
                    {profile.additionalEmails.map((email, index) => (
                      <li key={index} className="acl-email">
                        <span>{email}</span>
                        {/* Prevent default action on button click */}
                        <button type="button" onClick={(e) => { e.preventDefault(); handleRemoveEmail(email); }}>
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div >
                  <button
                    type="button"
                    className="modern-button save-button"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </form>

              {showResetPrompt && (
                <div className="reset-prompt">
                  <h4>Reset Password</h4>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                  />
                  <button onClick={handleUpdatePassword}>Send Reset Link</button>


                </div>
              )}
            </div>
          ) : (
            <div className="account-settings-box">
              <h1>Account Settings</h1>
              {loading && <div className="loading-spinner">Processing...</div>} {/* Show spinner if loading */}
              <h2>Subscription</h2>
              <div className="subscription-options">
                <p>Current Plan: {subscription.plan}</p>
                {subscription.plan !== 'Basic' && (
                  <p>Expires: {subscription.planExpiration}</p>
                )}
                <button
                  className="subscription-button"
                  onClick={handleSubscriptionUpgrade}
                >
                  Manage My Subscription
                </button>
              </div>


              <h2>Appearance</h2>
              <div className="appearance-options">
                <label>Theme</label>
                <select
                  value={appearance.theme}
                  onChange={(e) =>
                    setAppearance({ ...appearance, theme: e.target.value })
                  }
                  className="theme-select"
                >
                  <option value="Light">Light</option>
                  <option value="Dark">Dark</option>
                </select>
              </div>

              <h2>Note Categorization
              <span className="tooltip-container">
                      <span className="tooltip-icon"></span>
                      <span className="tooltip-text">
                        You can specify custom categories for each color to organize notes.
                      </span>
              </span>
              </h2>

              <div className="categorization-container">
                {Object.entries(appearance.categorization).map(([color, category]) => (
                  <div key={color} className="categorization-item">
                    <label className="categorization-label">{color}</label>
                    <input
                      type="text"
                      name={color}
                      value={category}
                      onChange={handleAppearanceChange}
                      className="categorization-input"
                    />
                  </div>
                ))}
              </div>

              <button type="button" className="modern-button save-button" onClick={handleSave} >
                Save
              </button>
            </div>
          )}
        </div> 


      </div>
      <div className="footer">
        <div className="footer-left">
          <span>© {new Date().getFullYear()} Ideal Scribe. All rights reserved.</span>
        </div>
        <nav className="footer-links">
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/support">Support</Link>
          <div className="social-links">
            <a href="https://medium.com" target="_blank" rel="noopener noreferrer"><FaMedium /></a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default AccountSettings;
