import React, { useState, useEffect, useRef  } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Header from './components/Header';
import Footer from './components/Footer';
import './pack-it.css';
import { useWebSocket } from './WebSocketContext';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAuthSession } from '@aws-amplify/auth';
import axios from 'axios';

const PackIt = () => {
  const [packIts, setPackIts] = useState([]);
  const [selectedPackIt, setSelectedPackIt] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [alerts, setAlerts] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;  
  const [filters, setFilters] = useState({
    upcoming: false,
    shared: false,
    completed: false
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { socket, clientId, connectionId, setConnectionId } = useWebSocket(); 
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const filteredPackIts = packIts.filter(packIt => {
  const searchMatch = packIt.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        packIt.location.toLowerCase().includes(searchTerm.toLowerCase());

                                          
    
    if (!searchMatch) return false;
    
    if (filters.upcoming) {
      const isUpcoming = new Date(packIt.date) > new Date();
      if (!isUpcoming) return false;
    }
    
    if (filters.shared && !packIt.isShared) return false;
    if (filters.completed && !packIt.isCompleted) return false;
    
    return true;
  });
  // Add near other useState() calls
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isChatMinimized, setIsChatMinimized] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);

          if (message.type === 'answer') {
            setIsWaitingForResponse(false);
            setHasError(false);
            //console.log(`answer recieved:`, message.data.answer);
            const bedrockMessage = { sender: 'bedrock', text: message.data.answer };
            setMessages((prevMessages) => [...prevMessages, bedrockMessage]);
          }
        } catch (error) {
          console.error('Error processing WebSocket message:', error);
        }
      };
    }
}, [socket, connectionId]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
    fetchPackIts();
  }, []);

  const formatChatMessage = (text) => {
    // Split text into segments by newlines or numbered list indicators
    const lines = text.split(/(?<=\n)|(?=\d\.\s)/);
    const formattedLines = [];
  
    lines.forEach((line, index) => {
      if (/^\d\.\s/.test(line)) {
        // Handle numbered list items
        formattedLines.push(
          <div key={index} className="chat-list-item">
            {line.trim()}
          </div>
        );
      } else if (line.trim() === '') {
        // Handle empty lines for spacing
        formattedLines.push(
          <div key={`${index}-spacer`} className="chat-spacer">
            &nbsp;
          </div>
        );
      } else {
        // Handle regular text as paragraphs
        formattedLines.push(
          <p key={index} className="chat-paragraph">
            {line.trim()}
          </p>
        );
      }
    });
  
    return formattedLines;
  };

  
  const handleSendMessage = async () => {
    if (inputMessage.trim()) {
      const userMessage = { sender: 'user', text: inputMessage };
      setMessages((prev) => [
        ...prev,
        { sender: 'user', text: inputMessage }
      ]);
      setInputMessage('');

      const session = await fetchAuthSession(); // Get session info
      const jwtToken = session?.idToken?.jwtToken;
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();
  
      if (!token) {
        alert('You need to be logged in to submit a request.');
        return;
      }
      console.log('ClientID is: ', connectionId);
      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
        //'InvocationType': 'Event',  //set to true when we get async to work
        'Authorization': `Bearer ${token}`,
      };

      const payload = {
        question: inputMessage,
        ClientID: connectionId
      };

      setHasError(false);
      setIsWaitingForResponse(true);

      // Send API request
      try {
        const response = await axios.post(`${apiUrl}/chatbot`, payload, { headers });
    
        if (response.status === 202 || response.status === 200) {
          //setIsWaitingForResponse(true);     //set to true when we get async to work
          //alert('Question created successfully!');
          //navigate('/pack-it'); // Navigate back to the Pack-It list
        } else {
          setIsWaitingForResponse(false);
          setHasError(true);
          throw new Error('Failed to create question');
        }
      } catch (error) {
        console.error('Error creating question:', error);
        setIsWaitingForResponse(false);
        setHasError(true);
        //alert('An error occurred while creating your AI request. Please try again.');
      }
    }
  };

  const fetchPackIts = async () => {
    try {
      const dummyData = Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        name: `Pack-It ${index + 1}`,
        location: `Location ${index + 1}`,
        date: new Date(2024, 0, index + 1),
        isShared: false,
        isCompleted: false
      }));
      setPackIts(dummyData);
      setAlerts(Math.floor(Math.random() * 5));
    } catch (error) {
      console.error('Error fetching pack-its:', error);
    }
  };

  const handleCreateNew = () => {
    /*
    const newPackIt = {
      id: Date.now(),
      name: 'New Pack-It',
      location: '',
      date: new Date(),
      isShared: false,
      isCompleted: false
    };
    setPackIts([...packIts, newPackIt]);
    setSelectedPackIt(newPackIt);
    */

    //connectionId  could pass connectionID here
    navigate('/create-pack-it');
  };

  const handlePackItSelect = (packIt) => {
    setSelectedPackIt(selectedPackIt?.id === packIt.id ? null : packIt);
  };

  // This ref points to a "dummy" element at the bottom of the chat
  const messagesEndRef = useRef(null);

  // Scroll to the bottom each time messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="app">

      <Header />
      <div className="content-container" >
        <div className="content-header">
          <div className="header-left">
            <h1>Pack-It Trips</h1>
          </div>
          <div className="header-actions">
          <button
              className="create-note-button-circle2"
              onClick={handleCreateNew}
              title="Create New Pack-It"
            > + </button>
            <div className="search-filter-group">
              <input
                type="text"
                placeholder="Search pack-its..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
              <button
                
                className="filter-button"
                onClick={() => setShowFilters(!showFilters)}
              >
                <i className="fas fa-filter"></i>
                <span>Filters</span>
              </button>
              {showFilters && (
                <div className="filter-dropdown">
                  <div className="filter-checkboxes">
                    <label>
                      <input
                        type="checkbox"
                        checked={filters.upcoming}
                        onChange={(e) =>
                          setFilters({ ...filters, upcoming: e.target.checked })
                        }
                      />
                      Upcoming
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={filters.shared}
                        onChange={(e) =>
                          setFilters({ ...filters, shared: e.target.checked })
                        }
                      />
                      Shared
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={filters.completed}
                        onChange={(e) =>
                          setFilters({ ...filters, completed: e.target.checked })
                        }
                      />
                      Completed
                    </label>
                  </div>
                </div>
              )}
            </div>
            <button
              className="toggle-chat-button"
              onClick={() => setIsChatMinimized(!isChatMinimized)}
            >
              {isChatMinimized ? 'Show Chat' : 'Hide Chat'}
            </button>
          </div>
        </div>



        <div className={`main-content-area ${isChatMinimized ? 'chat-hidden' : ''}`}>
          <div className="pack-its-list">
            {filteredPackIts.map(packIt => (
              <div className="pack-it-card" key={packIt.id}>
              <div
                className={`pack-it-header ${selectedPackIt?.id === packIt.id ? 'selected' : ''}`}
                onClick={() => handlePackItSelect(packIt)}
              >
                <div className="pack-it-summary">
                  <h3>{packIt.name}</h3>
                  <p>{packIt.location}</p>
                  <p>{new Date(packIt.date).toLocaleDateString()}</p>
                </div>
                <div className="pack-it-actions">
                  <button type="button" className="icon-button bookmark-icon"></button>
                  <button type="button" className="icon-button map-icon"></button>
                  <button type="button" className="icon-button share-icon"></button>
                  <button type="button" className="icon-button edit-icon"></button>
                  <button type="button" className="icon-button delete-icon"></button>


                  <button className="icon-button alert-button">
                    <i className="fas fa-bell"></i>
                    <span className="alert-text">Alerts</span>
                    {alerts > 0 && <span className="alert-badge">{alerts}</span>}
                  </button>
                </div>
              </div>
            
              {selectedPackIt?.id === packIt.id && (
                <div className="pack-it-details">
                  <div className="detail-section">
                    <h3>Itinerary</h3>
                    <div className="itinerary-timeline">
                      <div className="timeline-item">
                        <div className="time">Day 1</div>
                        <div className="activity">
                          <h4>Arrival</h4>
                          <p>Check-in at hotel</p>
                          <p>Welcome dinner</p>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  <div className="detail-section">
                    <h3>Weather Forecast</h3>
                    <div className="weather-grid">{/* Weather content */}</div>
                  </div>
            
                  <div className="detail-section">
                    <h3>Packing List</h3>
                    <div className="packing-checklist">{/* Packing list */}</div>
                  </div>
                </div>
              )}
            </div>            
            ))}
          </div>
          {/* This block goes where your chat container currently is */}
          {!isChatMinimized && (
            <div className="chat-container">
              <h3>Pip - Your travel Assistant</h3>
              <div className="chat-input">
                <input
                  type="text"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder="Type your question here..."
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                />
                <button onClick={handleSendMessage}>Send</button>
                {isWaitingForResponse && !hasError && (
                  <div className="waiting-indicator-spinner"></div>
                )}
                {hasError && (
                  <div className="waiting-indicator-error"></div>
                )}

              </div>

              <div className="chat-messages">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`chat-message ${msg.sender === 'user' ? 'user' : 'bedrock'}`}
                  >
                    {formatChatMessage(msg.text)}
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
            </div>
          )}




        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PackIt;
