import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './create-packit.css';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useWebSocket } from './WebSocketContext';

const CreatePackit = () => {
  const [formData, setFormData] = useState({
    tripName: '',
    description: '',
    startDate: null,
    endDate: null,
    isFlexible: false,
    category: '',
    tripType: '',
    includesKids: false,
    includesPets: false,
    receiveAlerts: false,
    alertFrequency: '',
    packingNeeds: [], 
    interests: [],
    selectedColors: [], // Added for color selection
    checkpoints: []
  });
  const navigate = useNavigate();
  const [colorCategories, setColorCategories] = useState({});
  const [allInterests, setAllInterests] = useState([
    'Attractions',
    'Food',
    'Art'
  ]); // Predefined and user-added bubbles
  const [customInterest, setCustomInterest] = useState('');
  const [showAddBubbleModal, setShowAddBubbleModal] = useState(false);
  const [destinations, setDestinations] = useState([{ id: Date.now(), name: '' }]); // Initial destination
  const apiUrl = process.env.REACT_APP_API_URL;
  const { connectionId } = useWebSocket(); 



  useEffect(() => {
    const fetchUserCategorization = async () => {
      try {
        const session = await fetchAuthSession(); // Modify as needed based on your authentication setup
        const jwtToken = session?.idToken?.jwtToken;
        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
  
        // Extract the email from the JWT token
        const user = getUserFromToken(token);
  
        if (!user) {
          alert('Failed to retrieve user from JWT token.');
          return;
        }
  
        const headers = {
          'Content-type': 'application/json; charset=UTF-8',
          'Authorization': `Bearer ${token}`
        };

        const url = `${apiUrl}/users/${encodeURIComponent(user)}`;
        // Make the GET request to the API with JWT in the header
        const response = await axios.get(url, { headers });

        const userData = response.data[0];
        const data = userData.categorization || {};
        setColorCategories(data);
      } catch (error) {
        console.error('Error fetching user categorization:', error);
      }
    };
    fetchUserCategorization();
  }, []);

  const getUserFromToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.sub;
    } catch (error) {
      console.error('Error parsing JWT token:', error);
      return null;
    }
  };

  const handleColorToggle = (color) => {
    setFormData((prev) => ({
      ...prev,
      selectedColors: prev.selectedColors.includes(color)
        ? prev.selectedColors.filter((c) => c !== color) // Remove if already selected
        : [...prev.selectedColors, color], // Add if not selected
    }));
  };


const handleAddDestination = () => {
  setDestinations([...destinations, { id: Date.now(), name: '' }]);
};

const handleUpdateDestination = (id, value) => {
  setDestinations(
    destinations.map((destination) =>
      destination.id === id ? { ...destination, name: value } : destination
    )
  );
};

const handlePackingNeedsToggle = (need) => {
    setFormData((prev) => ({
      ...prev,
      packingNeeds: prev.packingNeeds.includes(need)
        ? prev.packingNeeds.filter((n) => n !== need) // Remove if already selected
        : [...prev.packingNeeds, need] // Add if not selected
    }));
  };

const handleRemoveDestination = (id) => {
  setDestinations(destinations.filter((destination) => destination.id !== id));
};


  const handleInterestToggle = (interest) => {
    setFormData((prev) => ({
      ...prev,
      interests: prev.interests.includes(interest)
        ? prev.interests.filter((i) => i !== interest)
        : [...prev.interests, interest]
    }));
  };

  const handleAddCustomInterest = () => {
    if (customInterest.trim() && !allInterests.includes(customInterest)) {
      setAllInterests((prev) => [...prev, customInterest]);
      setFormData((prev) => ({
        ...prev,
        interests: [...prev.interests, customInterest]
      }));
      setCustomInterest('');
      setShowAddBubbleModal(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const session = await fetchAuthSession(); // Get session info
    const jwtToken = session?.idToken?.jwtToken;
    const token = (await fetchAuthSession()).tokens?.idToken?.toString();

    if (!token) {
      alert('You need to be logged in to create a Pack-It.');
      return;
    }
    console.log('ClientID is: ', connectionId);
    const headers = {
      'Content-type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${token}`,
    };
  
    const payload = {
      name: formData.tripName,
      description: formData.description,
      startDate: formData.startDate ? formData.startDate.toISOString() : null,
      endDate: formData.endDate ? formData.endDate.toISOString() : null,
      tripType: formData.tripType,
      includesKids: formData.includesKids,
      includesPets: formData.includesPets,
      alertFrequency: formData.alertFrequency,
      packingNeeds: formData.packingNeeds,
      interests: formData.interests,
      checkpoints: destinations.map((destination) => ({
        checkpointType: 'destination',
        name: destination.name,
      })),
      colorCategory: formData.selectedColors[0] || null, // Assume one color for simplicity
      ClientID: connectionId
    };
  
    try {
      const response = await axios.post(`${apiUrl}/briefings`, payload, { headers });
  
      if (response.status === 201 || response.status === 200) {
        alert('Pack-It created successfully!');
        navigate('/pack-it'); // Navigate back to the Pack-It list
      } else {
        throw new Error('Failed to create Pack-It');
      }
    } catch (error) {
      console.error('Error creating Pack-It:', error);
      alert('An error occurred while creating your Pack-It. Please try again.');
    }
  };
  

  return (
    <div className="create-packit-container" style={{ paddingBottom: 100 }}>
      <h1>Create Your Pack-It</h1>
      <form className="create-packit-form" onSubmit={handleSubmit}>
        <label>
          Trip Name
          <input
            type="text"
            name="tripName"
            value={formData.tripName}
            onChange={handleChange}
            placeholder="Enter trip name"
          />
        </label>
        <label>
          Description
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Enter trip description"
          ></textarea>
        </label>
        <div className="horizontal-group">
          <div>
            <label>Start Date</label>
            <DatePicker
              selected={formData.startDate}
              onChange={(date) => setFormData({ ...formData, startDate: date })}
              placeholderText="Select start date"
            />
          </div>
          <div>
            <label>End Date</label>
            <DatePicker
              selected={formData.endDate}
              onChange={(date) => setFormData({ ...formData, endDate: date })}
              placeholderText="Select end date"
            />
          </div>
        </div>
        <div className="horizontal-group">
        <label>Are these dates flexible?</label>
        <input
            type="checkbox"
            name="isFlexible"
            checked={formData.isFlexible}
            onChange={handleChange}
        />
        </div>

        <div className="icon-selection">
            <label>
                <span>Color Categories</span>
            </label>
            <div className="options">
                {Object.entries(colorCategories).map(([color, description]) => {
                const normalizedColor = color.toLowerCase().replace(/\s+/g, '-');

                // Create a reference to the color indicator element
                const getColorRGB = (className) => {
                    const div = document.createElement('div');
                    div.className = `color-indicator ${className}`;
                    document.body.appendChild(div);
                    const rgbValue = getComputedStyle(div).getPropertyValue('--custom-color');
                    document.body.removeChild(div);
                    return rgbValue;
                };

                const colorRGB = getColorRGB(normalizedColor);

                return (
                    <button
                    type="button"
                    key={color}
                    className={`icon-button ${
                        formData.selectedColors.includes(color) ? 'selected' : ''
                    }`}
                    onClick={() => handleColorToggle(color)}
                    >
                    <span
                        className="color-indicator"
                        style={{ backgroundColor: colorRGB }}
                    ></span>
                    {description}
                    </button>
                );
                })}
            </div>
        </div>

        <div className="icon-selection">
            <label><span>Trip Type</span></label>
          <div className="options">
            {['Solo', 'Couples', 'Friends', 'Family'].map((type) => (
              <button
                type="button"
                key={type}
                className={`icon-button ${formData.tripType === type.toLowerCase() ? 'selected' : ''}`}
                onClick={() => setFormData({ ...formData, tripType: type.toLowerCase() })}
              >
                <img src={`/images/${type.toLowerCase()}.jpeg`} alt={type} />
                {type}
              </button>
            ))}
          </div>
        </div>
        <div className="icon-selection">
            <label><span>Packing Needs</span></label>
            <div className="options">
            {['Lightweight', 'Formal', 'Snow', 'Hiking', 'Beach', 'Camping'].map((category) => (
            <button
                type="button"
                key={category}
                className={`icon-button ${formData.packingNeeds.includes(category.toLowerCase()) ? 'selected' : ''}`}
                onClick={() => handlePackingNeedsToggle(category.toLowerCase())}
            >
                <img src={`/images/${category.toLowerCase()}.jpeg`} alt={category} />
                {category}
            </button>
            ))}
          </div>
        </div>
        <label><span>Interests</span></label>
        <div className="bubbles-container">
        
          {allInterests.map((interest, index) => (
            <button
              type="button"
              key={index}
              className={`bubble ${
                formData.interests.includes(interest) ? 'selected' : ''
              }`}
              onClick={() => handleInterestToggle(interest)}
            >
              {interest}
            </button>
          ))}
          <button
            type="button"
            className="add-bubble-button"
            onClick={() => setShowAddBubbleModal(true)}
          >
            +
          </button>
        </div>
        {showAddBubbleModal && (
          <div className="modal">
            <div className="modal-content">
              <label>New Interest</label>
              <input
                type="text"
                value={customInterest}
                onChange={(e) => setCustomInterest(e.target.value)}
                placeholder="Enter new interest"
              />
              <button onClick={handleAddCustomInterest}>Add</button>
              <button onClick={() => setShowAddBubbleModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        )}
        <table className="options-table">
            <tbody>
                {/* Includes Kids */}
                <tr>
                <td><label>Does this trip include kids?</label></td>
                <td>
                    <input
                    type="checkbox"
                    name="includesKids"
                    checked={formData.includesKids}
                    onChange={handleChange}
                    />
                </td>
                </tr>
                {/* Includes Pets */}
                <tr>
                <td><label>Does this trip include pets?</label></td>
                <td>
                    <input
                    type="checkbox"
                    name="includesPets"
                    checked={formData.includesPets}
                    onChange={handleChange}
                    />
                </td>
                </tr>
                {/* Alert Frequency */}
                <tr>
                <td><label>Alert frequency when active:</label>
                </td>
                <td>
                    <div className="radio-options">
                    <label>
                        Hourly
                        <input
                        type="radio"
                        name="alertFrequency"
                        value="hourly"
                        checked={formData.alertFrequency === 'hourly'}
                        onChange={handleChange}
                        />
                    </label>
                    <label>
                        Daily
                        <input
                        type="radio"
                        name="alertFrequency"
                        value="daily"
                        checked={formData.alertFrequency === 'daily'}
                        onChange={handleChange}
                        />
                    </label>
                    <label>
                        None
                        <input
                        type="radio"
                        name="alertFrequency"
                        value="none"
                        checked={formData.alertFrequency === 'none'}
                        onChange={handleChange}
                        />
                    </label>
                    <span className="tooltip-container">
                        <span className="tooltip-icon"></span>
                        <span className="tooltip-text">
                            Alerting can be active up to 1 week before a trip and during the trip duration. 
                        </span>
                    </span>
                    </div>
                </td>
                </tr>
            </tbody>
        </table>

        <label>   

            <span className="tooltip-container">
            Additional Destinations 
                <span className="tooltip-icon"></span>
                <span className="tooltip-text">
                You can add additional cities or addresses to build your itinerary and trip.
                </span>
            </span>
            <div className="destinations-container">
            {destinations.map((destination) => (
                <div key={destination.id} className="destination-row">
                <input
                    type="text"
                    placeholder="Enter destination"
                    value={destination.name}
                    onChange={(e) => handleUpdateDestination(destination.id, e.target.value)}
                    className="categorization-input"
                />
                <button
                    type="button"
                    className="remove-button"
                    onClick={() => handleRemoveDestination(destination.id)}
                >
                    Remove
                </button>
                </div>
            ))}
            <button
                type="button"
                className="add-destination-button"
                onClick={handleAddDestination}
            >
                Add Destination
            </button>
            </div>


        </label>
        <div className="button-group">
        <button
            type="button"
            className="create-note-button cancel"
            onClick={() => navigate('/pack-it')}
        >
            Cancel
        </button>
        <button 
            type="button" 
            className="create-note-button"
            onClick={handleSubmit}
        >
            Create Pack-It
        </button>
        </div>









      </form>
    </div>
  );
};

export default CreatePackit;
