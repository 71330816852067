// src/components/Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  
  const handleLogout = () => {
    // TODO: Implement logout logic
    navigate('/');
  };

  return (
    <header className="header">
        <div className="banner">
            <div className="banner-left">
            <a href="/"><img src="./logo.png" alt="Ideal Scribe" className="logo" /></a>
            <Link to="/" className="title-link">
                <h1 className="title">Ideal Scribe</h1>
            </Link>
            <span className="beta-label">beta</span>
            </div>
            <nav className="nav-links">
            {/*<button className="night-mode-toggle" onClick={toggleNightMode}>
                {isNightMode ? 'Day Mode' : 'Night Mode'}
            </button>*/}
            {/*{showBriefings && <BriefingDisplay />}*/}
            <Link to="/app">Quick Notes</Link>
            <Link to="/account-settings">Account Settings</Link>
            <Link to="/startup-guide">Startup Guide</Link>
            <button className="night-mode-toggle" onClick={handleLogout}>Logout</button>
            
            </nav>
        </div>
    </header>
  );
};

export default Header;
